import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import Img from "gatsby-image"
import useEnrollmentCompleteImages from "./hooks/useEnrollmentCompleteImages"
import Button from "elements/Button"
import Layout from "layout"
import Container from "layout/Container"
import { redirectBasedOnPermissions } from "../../Auth/services/permissions"

import { AppContext } from "../../../context/AppContext"
import styles from "./utils/enrollmentComplete.module.scss"
import { getSignedInUser } from "../../Auth/services/user"
import { isBrowser } from "../../../services/general"

const EnrollmentComplete = (props) => {
  const { pageContext } = props
  const { module, content } = pageContext
  const { dispatch } = useContext(AppContext)

  const data = useEnrollmentCompleteImages()
  let redirectTo

  if (isBrowser()) {
    redirectTo = sessionStorage.getItem("redirectTo")
  }

  const handleOnClick = (e) => {
    let userData = getSignedInUser().userData
    let chosen = sessionStorage.getItem("programChosen")
    if (chosen) {
      redirectBasedOnPermissions(chosen, userData?.permissions)
    }

    // if (redirectTo) {
    //   navigate(redirectTo)
    // } else {
    navigate("/services")
    // }

    // else if (redirectTo === "/workplace-wellbeing") {
    //   navigate("/workplace-wellbeing")
    // } else {
    //   navigate("/")
    // }
  }

  useEffect(() => {
    const restartDetails = async () => {
      await dispatch({ type: "RESET_DETAILS" })
    }
    restartDetails()
  }, [dispatch])

  const assessmentMessage =
    content.text.split(".")[0].trim() +
    ". You may now answer your assessment form."

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: false }}
      seoTitle={module.seoTitle}
      pageContext={pageContext}
      polygonStyle="completePages"
    >
      <Container isCentered>
        <center>
          <Container isCentered mobile={10} tablet={6} desktop={4}>
            {content.icon && (
              <Img
                fluid={data[content.icon].childImageSharp.fluid}
                alt={`${module.name} Complete`}
                className={styles["image"]}
              />
            )}
          </Container>
          <h1 className="title my-2 has-text-primary">{module.title}</h1>
          <h4 className="pb-4">
            {redirectTo === "/workplace-wellbeing"
              ? assessmentMessage
              : content.text}
          </h4>
          <Button
            onClick={handleOnClick}
            className="button is-primary is-medium"
          >
            {redirectTo === "/workplace-wellbeing"
              ? "Answer Assessment"
              : "Book Teleconsult"}
          </Button>
        </center>
      </Container>
    </Layout>
  )
}

export default EnrollmentComplete
